import auth0 from 'auth0-js'
import defaultConfig from './config'
import Router from './lib/router'
import { version } from '../package.json'

export default class AuthenticationService {
  constructor (options = {}) {
    this.config = Object.assign(defaultConfig, options.config || {})
    this.router = options.router || Router
    this.store = options.store || window.sessionStorage
    this.auth = new auth0.WebAuth(this.config)
    this.profile = this.store.getItem('profile')
    this.version = version || 'latest'
  }

  login (options = {}) {
    this.auth.authorize(options)
  }

  signup (options = {}) {
    this.auth.authorize(Object.assign(options, { initialScreen: 'signUp' }))
  }

  handleCallback (redirectUri = `${window.location.origin}/${window.location.search}`) {
    this.auth.parseHash((error, authResult) => {
      const callbackURL = window.location.href
      if (error) {
        // NOTE: Redirect back to hosted login page and provide the error description and callback url
        this.login({ redirectUri: callbackURL, errorDescription: error.errorDescription })
      } else if (authResult && authResult.accessToken && authResult.idToken) {
        this._setSession(authResult)

        setTimeout(() => {
          // NOTE: Redirect to provided redirect URL on success
          this.router.replace(redirectUri)
        }, 500)
      } else {
        // NOTE: Redirect back to the callback url
        this.login({ redirectUri: callbackURL, errorDescription: 'Missing required parameter in response' })
      }
    })
  }

  // POD-1070: Hot-Fix only for POD frontend
  handleImpersonation (redirectUri = `${window.location.origin}/${window.location.search}`) {
    this.auth.parseHash({ __enableIdPInitiatedLogin: true }, (error, authResult) => {
      if (error) {
        window.alert(`error in handleImpersonation(): ${JSON.stringify(error)}`)
      } else {
        // At this point the access_token is stored as a opaque token...
        this._setSession(authResult)
        // ...But we need the access_token as jwt token for backend calls.
        // Calling auth0's checkSession does this for us.
        this.auth.checkSession({}, (err, result) => {
          if (err) {
            window.alert(`error in handleImpersonation(), checkSession(): ${JSON.stringify(err)}`)
          } else {
            this.store.setItem('access_token', result.accessToken)
            setTimeout(() => {
              this.router.replace(redirectUri)
            }, 500)
          }
        })
      }
    })
  }

  renewToken () {
    return this._checkSession()
  }

  isAuthenticated () {
    // NOTE: Check whether expires_at exists in storage and the current time is past the Access Token's expiry time
    const expiresAt = this.store.getItem('expires_at')
    return !!(new Date().getTime() < parseInt(expiresAt))
  }

  logout (options = {}) {
    // NOTE: Clear Access Token and ID Token from local storage
    this.profile = null
    this.store.removeItem('access_token')
    this.store.removeItem('id_token')
    this.store.removeItem('expires_at')
    this.store.removeItem('profile')
    this.auth.logout(Object.assign({ returnTo: options.returnTo || window.location.href }, options))
  }

  _checkSession () {
    return new Promise((resolve, reject) => {
      this.auth.checkSession({}, (err, authResult) => {
        if (err) {
          reject(err)
        } else {
          this._setSession(authResult)
          resolve(authResult)
        }
      })
    })
  }

  _setSession (authResult) {
    this.store.setItem('access_token', authResult.accessToken)
    this.store.setItem('id_token', authResult.idToken)
    // NOTE: Set the time that the Access Token will expire at
    this.store.setItem('expires_at', authResult.expiresIn * 1000 + parseInt(Date.now()))
    this.store.setItem('profile', JSON.stringify(authResult.idTokenPayload))
  }
}

window.AuthenticationService = AuthenticationService
